import classNames from 'classnames';
import {useState} from 'react';

import Toggle from '@/components/shared/Toggle/Toggle';

interface Props {
  handle: string;
  buttonContent: string | React.JSX.Element;
  reverseOnMobile?: boolean;
  tooltipContent: string | React.JSX.Element;
}

export function Tooltip({
  handle,
  buttonContent,
  reverseOnMobile,
  tooltipContent,
}: Props) {
  const [open, setOpen] = useState(false);

  return (
    <span className="relative inline-flex justify-content items-center">
      <button
        className="flex-auto focus:outline-state-focus"
        aria-controls={handle}
        aria-expanded={open}
        id={`toggle-button-${handle}`}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        onBlur={() => setOpen(false)}
        onFocus={() => setOpen(true)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        type="button"
      >
        {buttonContent}
      </button>
      <Toggle
        aria-labelledby={`toggle-button-${handle}`}
        hasAnimation={false}
        className={classNames(
          'absolute bg-white top-[100%] bottom-0 px-2 py-1 shadow-light rounded min-w-[16rem] z-10 h-fit',
          reverseOnMobile ? `right-0 lg:inset-x-0` : `inset-x-0`,
        )}
        isActive={open}
        toggleId={handle}
        tag="span"
      >
        {tooltipContent}
      </Toggle>
    </span>
  );
}
